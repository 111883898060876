import React from 'react';
import { Card } from 'react-bootstrap';
import '../assets/scss/index.scss';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

function CompSectionText({
  header,
  headertext,
  headerImg,
}: {
  header: string;
  headertext: string;
  headerImg?: string;
}) {
  return (
    <div className='mb-4'>
      <Card className='h-100'>
        <Card.Body>
          <Card.Title>
            <div className='d-flex '>
              <div className=''>
                <LazyLoadImage
                  effect='blur'
                  className='header-comp-img'
                  src={headerImg}
                  alt={header}
                />
              </div>
              <div className='d-flex flex-column justify-content-center ms-2 ms-lg-3'>
                <h3 className='color-black fnt-18  fw-700 lh-md-24 m-0'>{header}</h3>
              </div>
            </div>
          </Card.Title>
          <Card.Text>
            <div className='color-black fnt-14 fnt-lg-16  fw-400 lh-18 lh-md-24 ms-lg-0 ps-lg-0 w-lg-100'>
              {headertext}
            </div>
          </Card.Text>
        </Card.Body>
      </Card>
    </div>
  );
}

export default CompSectionText;
