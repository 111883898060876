import { Link } from 'gatsby';
import React from 'react';
import { Button, Container } from 'react-bootstrap';

function IdeationDescription({
  header,
  headerText,
  topheader,
}: {
  header: string;
  headerText: string;
  headerImg: string;
  topheader: string;
}) {
  return (
    <>
      <div className='service-background ideation-description-width bg-cover  min-vw-100 d-flex flex-column justify-content-end align-items-end'>
        <Container className='mx-auto'>
          <div className='d-flex flex-column justify-content-end align-items-center mt-10 mt-sm-7'>
            <h3 className='color-lightGreen mb-4 fnt-14  fnt-lg-16 text-uppercase fw-700'>
              {topheader}
            </h3>
            <div className='product-header-background mb-4'>
              <h1 className='fw-700 fnt-lg-40 fnt-24 color-white text-center my-4'>{header}</h1>
            </div>
            <p className='fw-500 fnt-14 fnt-lg-16 w-lg-74  lh-lg-24  color-white text-center mb-4 header-min-height'>
              {headerText}
            </p>
            <Link to='/contact-us/'>
              <Button className='fnt-14 fnt-lg-16 px-md-4 mb-lg-5 mb-4 btn-contact-us  border-0'>
                Connect Now
              </Button>
            </Link>
          </div>
        </Container>
      </div>
    </>
  );
}

export default IdeationDescription;
