import '../assets/scss/index.scss';
import React from 'react';
import { Container, Card } from 'react-bootstrap';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

function ConsSection({ data }: any) {
  return (
    <div className='common-background py-lg-5 py-4'>
      <Container>
        <div className='text-center fnt-md-18 fw-700 textColor'>PROCESS</div>
        <div className='text-center fnt-lg-32 fnt-md-32 fw-700 my-2 text-white'>{data.header}</div>
        <div className='d-flex justify-content-center'>
          <div className='fw-400 mb-3 mb-lg-4 pb-1 pb-lg-3 text-center text-white w-lg-74 fnt-14 fnt-lg-16'>
            {data.headerText}
          </div>
        </div>

        <div className='mob-description'>
          <div className='card-cont d-lg-flex'>
            {data.text.map((item: any, index: number) => (
              <Card key={index} className='card-containerization benefit-card'>
                <Card.Body>
                  <Card.Title>
                    <div className='align-items-center d-flex justify-content-between'>
                      <div className=''>
                        <LazyLoadImage
                          effect='blur'
                          className='header-comp-img'
                          src={item.icon}
                          alt={item.header}
                        />
                      </div>
                      <div className='d-flex flex-column justify-content-end'>
                        <h3 className='fw-600 textColor fnt-lg-36 fnt-24 m-0' key={index}>
                          {String(index + 1).padStart(2, '0')}
                        </h3>
                      </div>
                    </div>
                  </Card.Title>
                  <Card.Subtitle className='mb-2 text-muted'>
                    <div className=''>
                      <h3 className='color-black fnt-18  mb-3 fw-700 lh-md-24 m-0'>
                        {item.header}
                      </h3>
                    </div>
                  </Card.Subtitle>
                  <Card.Text>
                    <div className='fw-400 fnt-14 ms-lg-0 ps-lg-0 fnt-lg-16 lh-18 lh-md-24 color-black w-lg-100 '>
                      {item.headertext}
                    </div>
                  </Card.Text>
                </Card.Body>
              </Card>
            ))}
          </div>
        </div>
      </Container>
    </div>
  );
}

export default ConsSection;
